import axios from "axios";

const refineError = (error) => {
  let data = error.response.data;

  if (data) {
    if (data instanceof Array) {
      data = data[0];
    } else if (data instanceof Object) {
    } else {
      data = "";
    }
  } else {
    data = "";
  }
  return {
    status: error.response.status,
    data: data,
  };
};

class Http {
  constructor(url) {
    this.url = url;
    this.options = {};
  }

  async post(body) {
    try {
      return axios.post(this.url, body, this.options);
    } catch (error) {
      return refineError(error);
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (url) => {
  return new Http(url);
};
