import React from "react";
import FancyFeatureEight from "../../components/features/FancyFeatureEight";

const Main = () => {
  return (
    <>
      <div className="full-height-layout d-flex align-items-center container">
        <div className="coming-soon-content md-mt-70">
          <header className="logo coming-soon-brand d-flex justify-content-center">
            <img src="images/logo/caretbio.png" alt="brand logo" />
          </header>

          <div className="row">
            <div className="col-xl-11 m-auto">
              <FancyFeatureEight />
            </div>
          </div>

          <footer>
            <p className="copyright">
              © {new Date().getFullYear()} CARETBIO All rights reserved.
            </p>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Main;
