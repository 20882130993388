import React from "react";
import Main from "../views/all-home-pages/Main";

// Route Specific
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const Routes = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Main} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
