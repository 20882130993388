import http from "./constants/http";
import resources from "./constants/resources";

const uaInfo = async (body) => {
  const response = await http(resources.USER_AGENT).post(body);

  const { result, resultMsg } = response.data;

  if (response.status === 200) {
    if (result === "0000") {
      return "success";
    } else if (result === "9999") {
      return console.log(resultMsg);
    }
  } else {
    return console.log("useragent 가 정상적으로 처리되지 않았습니다.");
  }
};

export default uaInfo;
