import React from "react";

const FeaturesContent = [
  {
    img: "caretstore",
    title: "칼렛스토어",
    desc: (
      <p>
        스마트한 <span className="highlight-green">ESG 파트너</span> 칼렛스토어.
        <span className="highlight-green"> 지속가능한 포장재</span> 솔루션을
        제시합니다.
      </p>
    ),
    routePath: "https://www.caretstore.co.kr/",
    color: "left-btn",
    dataDelay: "100",
  },
  {
    img: "caretlink",
    title: "칼렛링크",
    desc: (
      <p>
        행복한 하루를 선물할 수 있도록 노력합니다.{" "}
        <span className="highlight-blue">자연부터, 마음으로, 사람에게.</span>
      </p>
    ),
    routePath: "http://www.caretlink.com/",
    color: "right-btn",
    dataDelay: "200",
  },
];

const FancyFeatureEight = () => {
  return (
    <div className="row">
      {FeaturesContent.map((val, i) => (
        <div
          className="col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-ten">
            <div className="icon">
              <img src={`images/brand-logo/${val.img}.png`} alt="icon" />
            </div>
            {val.desc}
            <div>
              <a
                href={val.routePath}
                target="_blank"
                rel="noreferrer"
                className={`page-btn ${val.color}`}
              >
                {val.title} 바로가기
              </a>
            </div>
          </div>
          {/* /.block-style-ten */}
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureEight;
