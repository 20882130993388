import React, { useEffect } from "react";
import Routes from "./router/Routes";
import { getUA } from "react-device-detect";
import ScrollToTop from "./components/ScrollToTop";
import uaInfo from "./managers/ua";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  const referrer = document.referrer;

  const body = {
    channel: "칼렛바이오",
    agent: getUA,
    referer: referrer,
  };

  const formData = new FormData();
  for (const key in body) {
    formData.append(key, body[key]);
  }

  uaInfo(formData);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;
